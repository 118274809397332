<template>
  <div>
    <div class="a-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="prePath + 'order'"
          >订单列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ orderNo }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div>
      <el-descriptions
        title="订单详情"
        :column="2"
        border
        v-loading="loading.info"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <template slot="extra">
          <template v-if="role === 'user'">
            <el-button
              style="margin-left: 1rem"
              size="mini"
              v-if="['NoneSubmitted'].includes(orderInfo.state)"
              @click="handleSubmit"
              type="primary"
              >提交</el-button
            >
            <el-popover
              v-show="['Delaying'].includes(orderInfo.state)"
              title="申请延长时间："
              placement="top"
              width="200"
              trigger="click"
            >
              <template>
                <div style="margin: 0.5rem 0">{{ orderInfo.newDeadline }}</div>
                <el-button
                  style="margin-top: 1rem"
                  size="mini"
                  @click="handleConfirmDelay(true)"
                  type="primary"
                  >同意</el-button
                >
                <el-button
                  style="margin-top: 1rem"
                  size="mini"
                  @click="handleConfirmDelay(false)"
                  type="danger"
                  >拒绝</el-button
                >
              </template>
              <el-button
                style="margin-left: 1rem"
                size="mini"
                slot="reference"
                type="danger"
                >同意延长打印</el-button
              >
            </el-popover>
            <el-popover
              v-show="
                !['Cancelled', 'PrintCompleted', 'Completed'].includes(
                  orderInfo.state
                )
              "
              title="延长打印天数"
              placement="top"
              width="200"
              trigger="click"
            >
              <template>
                <el-input
                  type="number"
                  :min="0"
                  placeholder="请输入天数"
                  v-model="deadline"
                >
                  <template slot="append">天</template>
                </el-input>
                <el-button
                  style="margin-top: 1rem"
                  size="mini"
                  @click="handleDelay"
                  type="primary"
                  >提交</el-button
                >
              </template>
              <el-button
                style="margin-left: 1rem"
                size="mini"
                slot="reference"
                type="danger"
                >延长打印时限</el-button
              >
            </el-popover>

            <el-button
              style="margin-left: 1rem"
              v-if="['NoneReceived', 'Received'].includes(orderInfo.state)"
              size="mini"
              @click="handleCancel"
              type="warning"
              >取消</el-button
            >
          </template>
          <template v-if="role === 'servicer'">
            <el-button
              style="margin-left: 1rem"
              v-if="['NoneSubmitted', 'NoneReceived'].includes(orderInfo.state)"
              size="mini"
              @click="handleAccept"
              type="primary"
              >接收</el-button
            >
            <el-popover
              v-show="['Overdue', 'RefuseDelay'].includes(orderInfo.state)"
              title="申请延长打印天数"
              placement="left"
              width="200"
              trigger="click"
              :disabled="ture"
            >
              <template>
                <el-input
                  type="number"
                  :min="0"
                  placeholder="请输入天数"
                  v-model="deadline"
                >
                  <template slot="append">天</template>
                </el-input>
                <el-button
                  style="margin-top: 1rem"
                  size="mini"
                  @click="handleApplyDelay"
                  type="primary"
                  >提交</el-button
                >
              </template>
              <el-button
                style="margin-left: 1rem"
                size="mini"
                type="danger"
                slot="reference"
                >申请延长打印</el-button
              >
            </el-popover>
            <el-button
              style="margin-left: 1rem"
              v-if="['PrintCompleted'].includes(orderInfo.state)"
              size="mini"
              @click="handleConfirmComplete"
              type="primary"
              >确认完成</el-button
            >
          </template>
        </template>
        <el-descriptions-item label="订单号">{{
          orderInfo.orderNo
        }}</el-descriptions-item>
        <el-descriptions-item label="状态">
          <el-tag size="small" :type="types[orderInfo.state]">{{
            orderInfo.state ? status[orderInfo.state] : ""
          }}</el-tag>
        </el-descriptions-item>
        <template v-if="role === 'user'">
          <el-descriptions-item label="服务商">{{
            orderInfo.manufacturerName
          }}</el-descriptions-item>
        </template>
        <template v-if="role === 'servicer'">
          <el-descriptions-item label="客户">{{
            orderInfo.customerName
          }}</el-descriptions-item>
        </template>
        <template v-if="role === 'user'">
          <el-descriptions-item label="联系方式">{{
            orderInfo.manufacturerContact
          }}</el-descriptions-item>
        </template>
        <template v-if="role === 'servicer'">
          <el-descriptions-item label="联系方式">{{
            orderInfo.customerContact
          }}</el-descriptions-item>
        </template>
        <el-descriptions-item label="工艺">{{
          orderInfo.technology
        }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
          orderInfo.createTime
        }}</el-descriptions-item>
        <el-descriptions-item label="打印时限">{{
          orderInfo.deadline
        }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{
          orderInfo.remarks ? orderInfo.remarks : "无"
        }}</el-descriptions-item>
      </el-descriptions>
      <div class="el-descriptions__title" style="margin: 1.2rem 0 20px">
        模型列表
      </div>
      <el-table :data="orderInfo.models">
        <el-table-column label="名称" prop="modelName"> </el-table-column>
        <el-table-column label="打印数量" prop="printCount"> </el-table-column>
        <el-table-column label="已打印数量" prop="printedCount">
        </el-table-column>
        <el-table-column label="最大返工数" prop="maxReworkCount">
        </el-table-column>
        <el-table-column label="已返工" prop="reworkedCount"> </el-table-column>
        <el-table-column label="状态" prop="state">
          <template slot-scope="scope">
            <template
              v-if="role === 'user' && ['Reworking'].includes(scope.row.state)"
            >
              <el-popconfirm
                @confirm="handleConfirmRework(scope.row, true)"
                @cancel="handleConfirmRework(scope.row, false)"
                confirm-button-text="同意"
                cancel-button-text="拒绝"
                cancel-button-type="Danger"
                icon="el-icon-info"
                icon-color="red"
                :title="'申请返工个数：' + scope.row.reworkingCount"
              >
                <a
                  slot="reference"
                  style="
                    color: #f56c6c;
                    cursor: pointer;
                    text-decoration: underline;
                  "
                  >返工待确认</a
                >
              </el-popconfirm>
            </template>
            <template v-else>
              <el-tag size="small" :type="types[scope.row.state]">{{
                scope.row.state ? status[scope.row.state] : ""
              }}</el-tag>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <template v-if="role === 'servicer'">
              <el-popover
                v-show="
                  !['Suspend'].includes(scope.row.state) &&
                  ['Received', 'Printing', 'PrintCompleted'].includes(
                    orderInfo.state
                  )
                "
                title="申请返工"
                placement="top"
                width="200"
                trigger="click"
              >
                <template>
                  <el-input
                    type="number"
                    :min="0"
                    placeholder="请输入返工个数"
                    v-model="count"
                  >
                    <template slot="append">个</template>
                  </el-input>
                  <el-button
                    style="margin-top: 1rem"
                    size="mini"
                    @click="handleApplyRework(scope.row)"
                    type="primary"
                    >提交</el-button
                  >
                </template>
                <el-button
                  style="margin-left: 1rem"
                  size="mini"
                  slot="reference"
                  type="danger"
                  >申请返工</el-button
                >
              </el-popover>
              <el-button
                style="margin-left: 1rem"
                v-if="['Suspend'].includes(scope.row.state)"
                size="mini"
                type="warning"
                @click="handleResumeModel(scope.row)"
                >解除挂起</el-button
              >
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customerId: localStorage.getItem("customerId"),
      orderId: this.$route.params.orderId,
      orderNo: JSON.parse(localStorage.getItem("order")).orderNo,
      prePath: this.$route.path.split("order")[0],
      role: this.$route.path.split("/")[1],
      orderInfo: {},
      deadline: undefined,
      count: undefined,
      status: this.$store.state.status,
      types: this.$store.state.types,
      loading: {
        info: true,
        printer: true,
      },
    };
  },
  mounted() {
    this.getOrderInfo();
  },
  methods: {
    handleShow(ref) {
      this.$refs[ref].doShow();
      console.log(ref, this.$refs[ref]);
      // let r;
      // r = this.$refs[ref];
      // r ? r.doShow() : "";
    },
    /**获取文件夹下模型文件列表请求 */
    getOrderInfo() {
      this.setDeafault();
      const orderId = this.orderId;
      if (orderId) {
        this.$axios.getOrderInfo({ orderId }).then((res) => {
          if (!res.error) {
            this.orderInfo = res;
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
          this.loading.info = false;
        });
      }
    },
    setDeafault() {
      this.deadline = undefined;
      this.count = undefined;
    },
    /**
     * 用户操作处理
     */
    /**提交订单 */
    handleSubmit() {
      const orderId = this.orderId;
      const customerId = this.customerId;
      if (orderId && customerId) {
        this.$axios.submitOrder({ orderId, customerId }).then((res) => {
          if (!res.error) {
            this.$message({
              message: "订单提交成功",
              type: "success",
            });
            this.getOrderInfo();
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
      }
    },
    /** 取消订单 */
    handleCancel() {
      const orderId = this.orderId;
      const customerId = this.customerId;
      if (orderId) {
        this.$axios.cancelOrder({ orderId, customerId }).then((res) => {
          if (!res.error) {
            this.$message({
              message: "订单取消成功",
              type: "success",
            });
            this.getOrderInfo();
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
      }
    },
    /** 同意/拒绝延长打印 */
    handleConfirmDelay(isPass) {
      const orderId = this.orderId;
      const customerId = this.customerId;
      if (orderId && customerId) {
        this.$axios
          .confirmDelay({ orderId, customerId, isPass })
          .then((res) => {
            if (!res.error) {
              this.$message({
                message: "延长打印成功",
                type: "success",
              });
              this.getOrderInfo();
            } else {
              this.$notify.error({
                title: "提示",
                message: res.error,
                duration: 2000,
              });
            }
          });
      }
      this.$refs["confirmDelay" + this.orderInfo.id].doClose();
    },
    /** 修改打印时限 */
    handleDelay() {
      const orderId = this.orderId;
      const customerId = this.customerId;
      let deadline = new Date(this.orderInfo.deadline);
      deadline.setDate(deadline.getDate() + parseInt(this.deadline));
      deadline = deadline.toISOString();
      if (orderId && customerId) {
        this.$axios.delay({ orderId, customerId, deadline }).then((res) => {
          if (!res.error) {
            this.$message({
              message: "修改打印时限成功",
              type: "success",
            });
            this.getOrderInfo();
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
      }
    },
    /** 模型：同意/拒绝返工 */
    handleConfirmRework(model, isPass) {
      /**orderId, customerId, modelId */
      const orderId = this.orderId;
      const customerId = this.customerId;
      const modelId = model.id;
      if (orderId && customerId) {
        this.$axios
          .confirmRework({ orderId, customerId, modelId, isPass })
          .then((res) => {
            if (!res.error) {
              this.$message({
                message: "返工成功",
                type: "success",
              });
              this.getOrderInfo();
            } else {
              this.$notify.error({
                title: "提示",
                message: res.error,
                duration: 2000,
              });
            }
          });
      }
    },
    /**
     * 服务商操作处理
     */
    /** 接受详情 */
    handleAccept() {
      const orderId = this.orderId;
      const manufacturerId = this.orderInfo.manufacturerID;
      if (orderId && manufacturerId) {
        this.$axios
          .acceptOrder({
            orderId,
            manufacturerId,
          })
          .then((res) => {
            if (!res.error) {
              this.$message({
                message: "接收订单成功",
                type: "success",
              });
              this.getOrderInfo();
            } else {
              this.$notify.error({
                title: "提示",
                message: res.error,
                duration: 2000,
              });
            }
          });
      }
    },
    /** 申请延长打印 */
    handleApplyDelay() {
      const orderId = this.orderId;
      const manufacturerId = this.orderInfo.manufacturerID;
      let deadline = new Date(this.orderInfo.deadline);
      deadline.setDate(deadline.getDate() + parseInt(this.deadline));
      deadline = deadline.toISOString();
      if (orderId && manufacturerId) {
        this.$axios
          .applyDelay({ orderId, manufacturerId, deadline })
          .then((res) => {
            if (!res.error) {
              this.$message({
                message: "提交延长请求成功",
                type: "success",
              });
              this.getOrderInfo();
            } else {
              this.$notify.error({
                title: "提示",
                message: res.error,
                duration: 2000,
              });
            }
          });
      }
    },
    /** 确认打印完成 */
    handleConfirmComplete() {
      const orderId = this.orderId;
      const manufacturerId = this.orderInfo.manufacturerID;
      if (orderId && manufacturerId) {
        this.$axios.confirmComplete({ orderId, manufacturerId }).then((res) => {
          if (!res.error) {
            this.$message({
              message: "打印完成确认成功",
              type: "success",
            });
            this.getOrderInfo();
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
      }
    },
    /** 模型：申请返工 */
    /**orderId, manufacturerId, modelId, count */
    handleApplyRework(model) {
      const orderId = this.orderId;
      const manufacturerId = this.orderInfo.manufacturerID;
      const modelId = model.id;
      const count = this.count;
      if (orderId && manufacturerId) {
        this.$axios
          .applyRework({ orderId, manufacturerId, modelId, count })
          .then((res) => {
            if (!res.error) {
              this.$message({
                message: "返工申请发送成功",
                type: "success",
              });
              this.getOrderInfo();
            } else {
              this.$notify.error({
                title: "提示",
                message: res.error,
                duration: 2000,
              });
            }
          });
      }
    },
    /** 模型：解除挂起 */
    /**orderId, manufacturerId, modelId */
    handleResumeModel(model) {
      const orderId = this.orderId;
      const manufacturerId = this.orderInfo.manufacturerID;
      const modelId = model.id;
      if (orderId && manufacturerId) {
        this.$axios
          .resumeModel({ orderId, manufacturerId, modelId })
          .then((res) => {
            if (!res.error) {
              this.$message({
                message: "解除挂起成功",
                type: "success",
              });
              this.getOrderInfo();
            } else {
              this.$notify.error({
                title: "提示",
                message: res.error,
                duration: 2000,
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
.el-popconfirm__action {
  padding: 0.5rem;
}
</style>